<template>
  <div class="videoList contentbox">
    <div class="topDiv" ref="topDiv" style="width: 1290px; margin: 0 auto">
      视频类型：
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        class="allCheck"
        >全部</el-checkbox
      >
      <el-checkbox-group v-model="checked" @change="handleCheckedCitiesChange">
        <el-checkbox
          v-for="(li, index) in list"
          :label="li.typecode"
          :key="index"
          >{{ li.typename }}</el-checkbox
        >
      </el-checkbox-group>
      <div class="p-t-10">
        <span
          v-for="(li, index) in ulList"
          @click="scrollToSection(index)"
          :class="{ active: active == index }"
          >{{ li.name }}</span
        >
      </div>
    </div>
    <div class="neirong" :style="heightStyle" ref="neirong">
      <vue-scroll :ops="ops" :style="{ width: '100%' }">
        <div style="width: 1290px; margin: 0 auto">
          <div
            v-for="(li, index) in ulList"
            class="p-t-10"
            :class="'myDiv' + index"
          >
            <el-card shadow="never">
              <div slot="header" class="clearfix cardTitle">
                <span class="titleSpan"></span>
                {{ li.name }}
              </div>
              <div class="section">
                <div>
                  <div
                    v-for="item in li.list"
                    class="item"
                    @click="seeVideo(item)"
                  >
                    <div style="width: 280px; height: 210px">
                      <img
                        v-if="item.videopreview == ''"
                        src="@assets/img/video.png"
                        alt=""
                        width="100%"
                      />
                      <img
                        v-else
                        :src="item.videopreview"
                        alt=""
                        width="100%"
                      />
                    </div>
                    <div
                      class="p-t-10 f-s-16 singleline"
                      :title="item.videoname"
                    >
                      {{ item.videoname }}
                    </div>
                    <div class="p-t-20 c-6">{{ item.typename }}</div>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div v-show="ulList.length == 0" class="imgBox" :style="heightStyle">
          <img
            src="../../assets/img/noData.png"
            alt=""
            style="display: block; margin: 0 auto"
          />
        </div>
      </vue-scroll>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="850px"
      v-if="dialogVisible"
    >
      <component :is="component" :popupData="popupData"></component>
    </el-dialog>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import videoDetail from "./videoDetail"; // 视频播放
export default {
  name: "videoList",
  components: {
    videoDetail
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      heightStyle: {},
      isAll: true,
      list: [],
      active: null,
      //
      checkAll: true,
      checked: [],
      isIndeterminate: true,
      typecode: "",
      ulList: [],
      component: "",
      popupData: {},
      dialogVisible: false,
      title: ""
    };
  },
  async created() {
    await this.getVideoTypeFirstlevel();
    this.getList();
  },
  mounted() {
    let that = this;
    let a = that.$refs.neirong.getBoundingClientRect().top;
    that.heightStyle = {
      height: window.innerHeight - a - 24 - 10 + "px"
    };
    window.onresize = function () {
      that.cssFun();
    };
  },
  methods: {
    cssFun() {
      let that = this;
      that.$nextTick(function () {
        setTimeout(function () {
          let topDiv = that.$refs.topDiv.offsetHeight;
          that.heightStyle = {
            height: window.innerHeight - topDiv - 70 - 12 - 24 - 10 + "px"
          };
        }, 500);
      });
    },
    scrollToSection(index) {
      const targetElement = document.querySelector(".myDiv" + index);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
        this.active = index;
      }
    },
    getVideoTypeFirstlevel() {
      return new Promise(reslove => {
        this.$api("videoManage.getVideoTypeFirstlevel")
          .invoke({})
          .then(({ data: { code, data } }) => {
            reslove();
            if (code == 1) {
              data.data.map(e => {
                e.active = false;
                e.list = [];
              });
              this.list = data.data;
            }
          })
          .catch(() => {});
      });
    },
    unique(arr) {
      // 去重
      return arr.filter((item, index, arr) => {
        //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item, 0) === index;
      });
    },
    async seeVideo(item) {
      console.log(item.fileurl);
      // return false;
      if (item.fileurl == "") {
        this.$message({
          showClose: true,
          message: "暂无视频！",
          type: "warning",
          duration: 1000
        });
        return false;
      } else {
        await this.saveSdlVideoVisits(item);
        this.component = "videoDetail";
        this.title = item.videoname;
        this.popupData = { rtsp: item.fileurl };
        this.dialogVisible = true;
      }
    },
    saveSdlVideoVisits(val) {
      return new Promise(reslove => {
        this.$api("videoManage.saveSdlVideoVisits")
          .invoke({ fkVideoid: val.pkId })
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
            } else {
            }
          })
          .catch(() => {});
      });
    },
    getList() {
      this.ulList = [];
      let typecode = "";
      if (this.checkAll) {
        typecode = "";
      } else {
        this.checked.map(e => {
          typecode += e + ",";
        });
        typecode = typecode.substring(0, typecode.lastIndexOf(","));
      }
      // console.log(typecode);
      let obj = {
        videoname: "",
        typecode: typecode,
        rows: 0
      };
      this.$api("videoManage.getSdlVideoAuthByList")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            if (data) {
              let arr = [];
              data.data.map(q => {
                arr.push(q.title);
              });
              arr = this.unique(arr);
              let list = [];
              arr.map(e => {
                list.push({
                  name: e,
                  list: []
                });
              });
              data.data.map(e => {
                list.map(q => {
                  if (e.title == q.name) {
                    q.list.push(e);
                  }
                });
              });
              this.ulList = list;
              this.active = null;
              this.cssFun();
            }
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        });
    },
    handleCheckAllChange(val) {
      console.log(val);
      this.checked = val ? this.list : [];
      this.isIndeterminate = false;
      this.getList();
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.list.length;
      this.getList();
    }
  }
};
</script>
<style lang="less" scoped>
.videoList {
  .topDiv {
    border: 1px solid #ddd;
    padding: 12px;
    padding-left: 20px;
    span {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      // padding: 0 8px;
      margin-right: 20px;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
    }
    span.active {
      color: #0e90fe;
      border-color: #0e90fe;
    }
  }
  .el-card {
    .cardTitle {
      position: relative;
      padding-left: 10px;
      color: #333;
      font-size: 16px;
      .titleSpan {
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
    .item {
      padding: 10px;
      border: 1px solid #ddd;
      display: inline-block;
      margin-right: 20px;
      width: 285px;
      width: 302px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .item:nth-child(4n) {
      margin-right: 0;
    }
  }
  .imgBox {
    display: flex;
    align-items: center;
    img {
      display: block;
      margin: 0px auto;
      text-align: center;
      align-items: center;
      width: 242px;
      height: 165px;
    }
  }
}
</style>
<style lang="less">
.videoList {
  .el-card {
    .el-card__body {
      padding: 10px !important;
    }
  }
  .el-checkbox {
    .el-checkbox__input {
      display: none;
    }
  }
  .allCheck {
    margin-right: 30px;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    padding: 0 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    .el-checkbox__label {
      padding-left: 0;
    }
  }
  .allCheck.is-checked {
    background: #0e90fe;
    color: #fff;
    border-color: #0e90fe;
    .el-checkbox__label {
      color: #fff !important;
    }
  }
  .el-checkbox-group {
    display: inline;
    .el-checkbox__label {
      padding-left: 0;
    }
    .el-checkbox {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      padding: 0 8px;
      border-radius: 4px;
      border: 1px solid #ddd;
    }
    .el-checkbox.is-checked {
      background: #0e90fe;
      color: #fff;
      border-color: #0e90fe;
      .el-checkbox__label {
        color: #fff !important;
      }
    }
  }
}
</style>
