<template>
  <div class="rtspVideo_yes" style="height: 500px">
    <iframe
      id="childFrame"
      :src="'playVideo/camera.html?rstp=' + popupData.rtsp"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "videoDetail",
  props: ["popupData"]
};
</script>

<style>
iframe {
  vertical-align: top;
}
</style>
